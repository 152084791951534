<template>
  <section class="hero is-fullheight-with-navbar is-bg-gray p-6">
    <b-modal v-model="showQuestionnaireReview" class="p-6" full-screen>
      <dpt-questionnaire v-if="showQuestionnaireReview" :questionnaire-id="selectedQuestionnaireId" :isPreview="false"
                         :reviewed-user-id="selectedUserId"
                         :isReview="true" @cancelReview="handleCancelReview"
                         @questionnaireApproved="handleQuestionnaireApproved"></dpt-questionnaire>
    </b-modal>
    <div id="admin">
      <div class="hero-head">
        <div class="navbar">
          <div class="container">
            <div class="title">
              <h1 class="title is-1">Consultoría APT</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-body">
        <div class="container has-text-left">
          <div class="box p-6">
            <div class="block">
              <h3 class="title is-4">Progreso:</h3>
            </div>
            <div class="block">
              <b-progress format="percent" :max="100" size="is-large">
                <template #bar>
                  <b-progress-bar :value="10" type="is-success" show-value>Aprobados</b-progress-bar>
                  <b-progress-bar :value="28" type="is-warning" show-value>Revisados</b-progress-bar>
                  <b-progress-bar :value="57" show-value>Cumplimentados</b-progress-bar>
                </template>
              </b-progress>
            </div>
          </div>
          <div class="box p-6 ">
            <div class="block">
              <h3 class="title is-4">Consultas:</h3>
            </div>
            <div class="block">
              <b-table
                :data="userQuestionnaire"
                :hoverable="isHoverable"
                :loading="loadingDptAnswers"
                :current-page.sync="currentPage"
              >
                <b-table-column centered field="name" label="Nombre" sortable v-slot="props">
                  {{ props.row.name }}
                </b-table-column>
                <b-table-column centered field="company" label="Empresa" sortable v-slot="props">
                  {{ props.row.company.name }}
                </b-table-column>
                <b-table-column centered field="questionnaire" label="Ficha de Análisis" sortable v-slot="props">
                  {{ props.row.questionnaireName }}
                </b-table-column>
                <b-table-column centered field="state" label="Estado" sortable v-slot="props">
                  <b-tag :type="handleQuestionnaireTag(props.row.status)">{{ handleQuestionnaireStatus(props.row.status)
                    }}</b-tag>
                </b-table-column>
                <b-table-column centered label="Acciones" v-slot="props">
                  <el-tooltip v-if="props.row.status.id < 4" class="item" effect="dark"
                              content="Revisar"
                              placement="top">
                    <el-button size="mini" type="success" circle @click="handleReviewQuestionnaire(props.row)">
                      <v-icon icon="pen"></v-icon>
                    </el-button>
                  </el-tooltip>
                  <b-tag v-else type="is-success">{{ props.row.status.id &lt; 3 ? 'Pendiente de respuesta' :
                    'Revisado'}}</b-tag>
                </b-table-column>
              </b-table>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-foot">
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import * as types from '@/store/types'
import DptQuestionnaire from '@/components/questionnaire/DptQuestionnaire'
export default {
  components: {
    DptQuestionnaire
  },
  data () {
    return {
      userQuestionnaire: [],
      currentPage: 1,
      filters: {
        selectedCompany: null
      },
      isHoverable: true,
      loadingDptAnswers: false,
      pageSize: 20,
      selectedUserId: null,
      selectedQuestionnaireId: null,
      totalElements: 200,
      loadingUserQuestionnaire: false,
      showQuestionnairePreview: false,
      showQuestionnaireReview: false
    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    })
  },
  methods: {
    fetchUserQuestionnaire () {
      this.loadingUserQuestionnaire = true
      this.axios.get(process.env.VUE_APP_API_GET_USER, {
        params: {
          searchQuery: 'company.id:' + this.user.company.id + ',role.id>2',
          page: 0,
          size: 2000
        }
      }).then(response => {
        const userData = response.data.content
        this.userQuestionnaire = []
        let userQuestionnaires
        for (let i = 0; i < userData.length; i++) {
          userQuestionnaires = userData[i].userQuestionnaires

          for (let j = 0; j < userQuestionnaires.length; j++) {
            this.userQuestionnaire.push({
              id: userData[i].id,
              name: userData[i].name,
              company: userData[i].company,
              questionnaireId: userQuestionnaires[j].questionnaireId,
              questionnaireName: userQuestionnaires[j].questionnaireName,
              status: userQuestionnaires[j].status
            })
          }
        }
      }).catch(err => {
        console.error('Error al obtener los datos de la empresa', err)
      }).finally(() => {
        this.loadingUserQuestionnaire = false
      })
    },
    handleCancelReview () {
      this.resetStatus()
    },
    handleQuestionnaireApproved () {
      this.fetchUserQuestionnaire()
      this.resetStatus()
    },
    handleQuestionnaireTag (status) {
      let tag = null
      if (!status) {
        tag = 'Pendiente de responder'
      } else if (status.id <= 2) {
        tag = 'is-info'
      } else if (status.id === 3) {
        tag = 'is-danger'
      } else if (status.id > 3) {
        tag = 'is-success'
      }
      return tag
    },
    handleQuestionnaireStatus (status) {
      let label = null
      if (!status) {
        label = 'Pendiente de responder'
      } else if (status.id === 2) {
        label = 'Borrador'
      } else if (status.id === 3) {
        label = 'Pendiente de revisión'
      } else if (status.id === 4) {
        label = 'Aprobado'
      }
      return label
    },
    handleReviewQuestionnaire (data) {
      this.selectedUserId = data.id
      this.selectedQuestionnaireId = data.questionnaireId
      this.showQuestionnaireReview = true
    },
    handleSelectCompany (value) {
      this.filters.selectedCompany = value
    },
    resetStatus () {
      this.showQuestionnaireReview = false
      this.selectedQuestionnaireId = null
      this.selectedUserId = null
    }
  },
  created () {
    this.fetchUserQuestionnaire()
  }
}
</script>

<style lang="scss">

</style>
