var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "hero is-fullheight-with-navbar is-bg-gray p-6"
  }, [_c('b-modal', {
    staticClass: "p-6",
    attrs: {
      "full-screen": ""
    },
    model: {
      value: _vm.showQuestionnaireReview,
      callback: function callback($$v) {
        _vm.showQuestionnaireReview = $$v;
      },
      expression: "showQuestionnaireReview"
    }
  }, [_vm.showQuestionnaireReview ? _c('dpt-questionnaire', {
    attrs: {
      "questionnaire-id": _vm.selectedQuestionnaireId,
      "isPreview": false,
      "reviewed-user-id": _vm.selectedUserId,
      "isReview": true
    },
    on: {
      "cancelReview": _vm.handleCancelReview,
      "questionnaireApproved": _vm.handleQuestionnaireApproved
    }
  }) : _vm._e()], 1), _c('div', {
    attrs: {
      "id": "admin"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "hero-body"
  }, [_c('div', {
    staticClass: "container has-text-left"
  }, [_c('div', {
    staticClass: "box p-6"
  }, [_vm._m(1), _c('div', {
    staticClass: "block"
  }, [_c('b-progress', {
    attrs: {
      "format": "percent",
      "max": 100,
      "size": "is-large"
    },
    scopedSlots: _vm._u([{
      key: "bar",
      fn: function fn() {
        return [_c('b-progress-bar', {
          attrs: {
            "value": 10,
            "type": "is-success",
            "show-value": ""
          }
        }, [_vm._v("Aprobados")]), _c('b-progress-bar', {
          attrs: {
            "value": 28,
            "type": "is-warning",
            "show-value": ""
          }
        }, [_vm._v("Revisados")]), _c('b-progress-bar', {
          attrs: {
            "value": 57,
            "show-value": ""
          }
        }, [_vm._v("Cumplimentados")])];
      },
      proxy: true
    }])
  })], 1)]), _c('div', {
    staticClass: "box p-6 "
  }, [_vm._m(2), _c('div', {
    staticClass: "block"
  }, [_c('b-table', {
    attrs: {
      "data": _vm.userQuestionnaire,
      "hoverable": _vm.isHoverable,
      "loading": _vm.loadingDptAnswers,
      "current-page": _vm.currentPage
    },
    on: {
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  }, [_c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "name",
      "label": "Nombre",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.name) + " ")];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "company",
      "label": "Empresa",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.company.name) + " ")];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "questionnaire",
      "label": "Ficha de Análisis",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.questionnaireName) + " ")];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "state",
      "label": "Estado",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('b-tag', {
          attrs: {
            "type": _vm.handleQuestionnaireTag(props.row.status)
          }
        }, [_vm._v(_vm._s(_vm.handleQuestionnaireStatus(props.row.status)))])];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "label": "Acciones"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [props.row.status.id < 4 ? _c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Revisar",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "success",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleReviewQuestionnaire(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "pen"
          }
        })], 1)], 1) : _c('b-tag', {
          attrs: {
            "type": "is-success"
          }
        }, [_vm._v(_vm._s(props.row.status.id < 3 ? 'Pendiente de respuesta' : 'Revisado'))])];
      }
    }])
  })], 1)], 1)])])]), _c('div', {
    staticClass: "hero-foot"
  })])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hero-head"
  }, [_c('div', {
    staticClass: "navbar"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('h1', {
    staticClass: "title is-1"
  }, [_vm._v("Consultoría APT")])])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block"
  }, [_c('h3', {
    staticClass: "title is-4"
  }, [_vm._v("Progreso:")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block"
  }, [_c('h3', {
    staticClass: "title is-4"
  }, [_vm._v("Consultas:")])]);
}]

export { render, staticRenderFns }